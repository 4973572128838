import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import CallButton from "../components/callButton"
import ShopButton from "../components/shopButton"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { slugify } from "../helpers"
const IndexPage = (props) => {

  const data = useStaticQuery(graphql`
    query {
      allContentfulDoctor {
        edges {
          node {
            id
            name
            picture {
              id
              localFile {
                childImageSharp {
                  fluid(maxWidth: 288) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
      allContentfulLocation {
        edges {
          node {
            addressPrimaryLine
            city
            phone
            state
            zipCode
            name
            id
            phone
            node_locale
            url
            address
            location {
              lon
              lat
            }
          }
        }
      }
      associationsImage: file(relativePath: { eq: "associations.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 890) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      allContentfulReviewForLocations {
        edges {
          node {
            id
            name
            rating
            description {
              description
            }
          }
        }
      }
    }
  `)

  const locations = data.allContentfulLocation.edges
  const doctors = data.allContentfulDoctor.edges
  const reviews = data.allContentfulReviewForLocations.edges
  const p = props

  return (
    <Layout>
      <SEO title="U&M Family Eyecare" />

      <div className="container mx-auto lg:py-8">
        <div className="flex flex-col lg:flex-row justify-center lg:mb-12">
          <div className="hidden lg:block w-1/2 mb-4 mx-auto min-h-full hero-image"/>
          <div className="block lg:hidden w-full mb-4 hero-image h-40 md:h-128 min-h-full"/>
          <div className="w-full lg:w-1/2 lg:pb-6 px-4 lg:px-8">
            <h1 className="font-bold font-serif  text-center leading-tight text-navy-500 my-4 lg:mb-8 text-2xl lg:text-left lg:text-4xl ">A family eye care provider, with emphasis on care.</h1>
            <div className=" flex justify-center mb-4 lg:mb-6"><CallButton /></div>

            {reviews.map(({ node: review }, index) => {
              return index < 2 && <div key={review.id} className="text-navy-500 flex flex-col text-left px-2 lg:p-0 mb-4 hidden lg:block">
                <svg className="mb-2 -ml-1" width="112" height="22" viewBox="0 0 112 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.9997 15.8309L16.6647 19.25L15.1613 12.8059L20.1663 8.47004L13.5755 7.90171L10.9997 1.83337L8.42384 7.90171L1.83301 8.47004L6.82884 12.8059L5.33467 19.25L10.9997 15.8309Z" fill="#FCCF5C" />
                  <path d="M33.5 15.8309L38.9075 19.25L37.4725 12.8059L42.25 8.47004L35.9588 7.90171L33.5 1.83337L31.0412 7.90171L24.75 8.47004L29.5187 12.8059L28.0925 19.25L33.5 15.8309Z" fill="#FCCF5C" />
                  <path d="M55.9997 15.8309L61.6647 19.25L60.1613 12.8059L65.1663 8.47004L58.5755 7.90171L55.9997 1.83337L53.4238 7.90171L46.833 8.47004L51.8288 12.8059L50.3347 19.25L55.9997 15.8309Z" fill="#FCCF5C" />
                  <path d="M78.5 15.8309L83.9075 19.25L82.4725 12.8059L87.25 8.47004L80.9588 7.90171L78.5 1.83337L76.0412 7.90171L69.75 8.47004L74.5187 12.8059L73.0925 19.25L78.5 15.8309Z" fill="#FCCF5C" />
                  <path d="M101 15.8309L106.665 19.25L105.161 12.8059L110.166 8.47004L103.576 7.90171L101 1.83337L98.4238 7.90171L91.833 8.47004L96.8288 12.8059L95.3347 19.25L101 15.8309Z" fill="#FCCF5C" />
                </svg>
                <p className=" italic">{review.description.description + ' - ' + review.name}</p>
              </div>
            })}
            {reviews.map(({ node: review }, index) => {
              return index < 1 && <div key={review.id} className="text-navy-500 flex flex-col text-left px-2 lg:p-0 mb-4 lg:hidden block items-center text-center">
                <svg className="mb-2 -ml-1" width="112" height="22" viewBox="0 0 112 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.9997 15.8309L16.6647 19.25L15.1613 12.8059L20.1663 8.47004L13.5755 7.90171L10.9997 1.83337L8.42384 7.90171L1.83301 8.47004L6.82884 12.8059L5.33467 19.25L10.9997 15.8309Z" fill="#FCCF5C" />
                  <path d="M33.5 15.8309L38.9075 19.25L37.4725 12.8059L42.25 8.47004L35.9588 7.90171L33.5 1.83337L31.0412 7.90171L24.75 8.47004L29.5187 12.8059L28.0925 19.25L33.5 15.8309Z" fill="#FCCF5C" />
                  <path d="M55.9997 15.8309L61.6647 19.25L60.1613 12.8059L65.1663 8.47004L58.5755 7.90171L55.9997 1.83337L53.4238 7.90171L46.833 8.47004L51.8288 12.8059L50.3347 19.25L55.9997 15.8309Z" fill="#FCCF5C" />
                  <path d="M78.5 15.8309L83.9075 19.25L82.4725 12.8059L87.25 8.47004L80.9588 7.90171L78.5 1.83337L76.0412 7.90171L69.75 8.47004L74.5187 12.8059L73.0925 19.25L78.5 15.8309Z" fill="#FCCF5C" />
                  <path d="M101 15.8309L106.665 19.25L105.161 12.8059L110.166 8.47004L103.576 7.90171L101 1.83337L98.4238 7.90171L91.833 8.47004L96.8288 12.8059L95.3347 19.25L101 15.8309Z" fill="#FCCF5C" />
                </svg>
                <p className=" italic">{review.description.description + ' - ' + review.name}</p>
              </div>
            })}
            <div className="lg:flex hidden -ml-2">
              {locations.map(({ node: location }) => (
                <Link className="flex items-end mr-5 " key={location.id} to={'/' + location.url} aria-label={location.name + ' U & M Family Eye Care'}>
                  <div className="flex items-center">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 11.5C11.337 11.5 10.7011 11.2366 10.2322 10.7678C9.76339 10.2989 9.5 9.66304 9.5 9C9.5 8.33696 9.76339 7.70107 10.2322 7.23223C10.7011 6.76339 11.337 6.5 12 6.5C12.663 6.5 13.2989 6.76339 13.7678 7.23223C14.2366 7.70107 14.5 8.33696 14.5 9C14.5 9.3283 14.4353 9.65339 14.3097 9.95671C14.1841 10.26 13.9999 10.5356 13.7678 10.7678C13.5356 10.9999 13.26 11.1841 12.9567 11.3097C12.6534 11.4353 12.3283 11.5 12 11.5ZM12 2C10.1435 2 8.36301 2.7375 7.05025 4.05025C5.7375 5.36301 5 7.14348 5 9C5 14.25 12 22 12 22C12 22 19 14.25 19 9C19 7.14348 18.2625 5.36301 16.9497 4.05025C15.637 2.7375 13.8565 2 12 2Z" fill="#D8E0E9" />
                    </svg>
                    <p className="text-navy-500 font-bold tracking-wide">{location.name}</p>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
        <Img fluid={data.associationsImage.childImageSharp.fluid} alt={'Associations'} className="max-w-4xl mx-auto mb-12 hidden lg:block" />
        <h2 className="text-center uppercase text-xl text-navy-500 font-thin mb-4 lg:mb-12 ">know your eye care doctors</h2>
        <div className="flex flex-wrap items-center justify-center px-2 mb-12 lg:mb-12 ">
          {doctors.map(({ node: doctor }) => {
            return <Link to={'/optometrist-doctors/' + slugify(doctor.name)} key={doctor.id} aria-label={doctor.name + ' U & M Family Eye Care'}
              className="flex flex-col items-center font-serif text-center text-navy-600 mb-4 lg:mb-4 mx-4">
              <Img className="w-48 lg:56 rounded-full border-2 border-white" fluid={doctor.picture.localFile.childImageSharp.fluid} />
              <p className="font-bold">{doctor.name}</p>
            </Link>
          })}
        </div>
        <div className="max-w-4xl mx-auto text-navy-500 text-center px-4 mb-4 lg:mb-12">
          <h2 className="text-center uppercase text-xl mt-2 lg:my-2 text-navy-500 font-thin mb-4 lg:mb-4">Let Our Family Take Care of your Family's Eyecare Needs</h2>
          <p className="mb-2">At U & M Family Eyecare, P.C. our highest goal is to improve our patients’ lives through top notch vision care in a convenient, professional setting. We are proud to offer state of the art medical and optometric services in 4 convenient locations serving Gwinnett, Jackson, Fulton and Banks County. Our company was started with the husband and wife team of Dr. Ishaque and Dr. Bashir with the premise of treating our patients as we would want our family to be cared for.</p>
          <p className="mb-2">Even though U & M Family Eyecare has grown over the last fifteen years to include 4 offices and 6 doctors, those strong family principles are still the guiding values that shape our practice today. We specialize in all forms of primary care optometry with special emphasis in Ocular Disease, specialty contact lens fitting, co-management of refractive and cataract surgery, pediatric exams and Low Vision.</p>
          <p className="mb-2">Our doctors and staff are knowledgeable and friendly and we pride ourselves in treating all our patients with respect and care. Welcome to U & M Family Eyecare, and please let our family take care of all your family’s Eyecare needs!</p>
        </div>
        <div className="block px-4 mb-4">
          <Img fluid={data.associationsImage.childImageSharp.fluid} className="max-w-4xl mx-auto mb-12 lg:hidden px-4" />
        </div>

        <div className="max-w-xl mx-auto">
          <h2 className="text-center uppercase text-xl mb-4 text-navy-500 font-thin">you are in good company</h2>
          <div className="flex flex-col items-center justify-center">
            {reviews.map(({ node: review }, index) => {
              return index < 4 && <div key={review.id} className="text-navy-500 flex flex-col text-left mb-4 items-center text-center px-6 lg:p-0">
                <svg className="mb-2 -ml-1" width="112" height="22" viewBox="0 0 112 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.9997 15.8309L16.6647 19.25L15.1613 12.8059L20.1663 8.47004L13.5755 7.90171L10.9997 1.83337L8.42384 7.90171L1.83301 8.47004L6.82884 12.8059L5.33467 19.25L10.9997 15.8309Z" fill="#FCCF5C" />
                  <path d="M33.5 15.8309L38.9075 19.25L37.4725 12.8059L42.25 8.47004L35.9588 7.90171L33.5 1.83337L31.0412 7.90171L24.75 8.47004L29.5187 12.8059L28.0925 19.25L33.5 15.8309Z" fill="#FCCF5C" />
                  <path d="M55.9997 15.8309L61.6647 19.25L60.1613 12.8059L65.1663 8.47004L58.5755 7.90171L55.9997 1.83337L53.4238 7.90171L46.833 8.47004L51.8288 12.8059L50.3347 19.25L55.9997 15.8309Z" fill="#FCCF5C" />
                  <path d="M78.5 15.8309L83.9075 19.25L82.4725 12.8059L87.25 8.47004L80.9588 7.90171L78.5 1.83337L76.0412 7.90171L69.75 8.47004L74.5187 12.8059L73.0925 19.25L78.5 15.8309Z" fill="#FCCF5C" />
                  <path d="M101 15.8309L106.665 19.25L105.161 12.8059L110.166 8.47004L103.576 7.90171L101 1.83337L98.4238 7.90171L91.833 8.47004L96.8288 12.8059L95.3347 19.25L101 15.8309Z" fill="#FCCF5C" />
                </svg>
                <p className=" italic">{review.description.description + ' - ' + review.name}</p>
              </div>
            })}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
