import React from "react"
import { Link } from "gatsby"

import MakeAppointment from './makeAppointment'



const CallButton = (props) => {
      return (
        <div className="flex flex-col items-center">
        <a href={'https://www.e-dr.com/umeyecare'}
           target="_blank"
          className="font-sans px-2 py-2 tracking-wide radial-gradient-gray rounded shadow flex items-center block mx-auto text-center cursor-pointer phone justify-center mb-2">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.2 19.2C19.8365 19.2 20.447 19.4529 20.8971 19.9029C21.3471 20.353 21.6 20.9635 21.6 21.6C21.6 22.2365 21.3471 22.847 20.8971 23.2971C20.447 23.7471 19.8365 24 19.2 24C17.868 24 16.8 22.92 16.8 21.6C16.8 20.268 17.868 19.2 19.2 19.2ZM0 0H3.924L5.052 2.4H22.8C23.1183 2.4 23.4235 2.52643 23.6485 2.75147C23.8736 2.97652 24 3.28174 24 3.6C24 3.804 23.94 4.008 23.856 4.2L19.56 11.964C19.152 12.696 18.36 13.2 17.46 13.2H8.52L7.44 15.156L7.404 15.3C7.404 15.3796 7.43561 15.4559 7.49187 15.5121C7.54813 15.5684 7.62444 15.6 7.704 15.6H21.6V18H7.2C5.868 18 4.8 16.92 4.8 15.6C4.8 15.18 4.908 14.784 5.088 14.448L6.72 11.508L2.4 2.4H0V0ZM7.2 19.2C7.83652 19.2 8.44697 19.4529 8.89706 19.9029C9.34714 20.353 9.6 20.9635 9.6 21.6C9.6 22.2365 9.34714 22.847 8.89706 23.2971C8.44697 23.7471 7.83652 24 7.2 24C5.868 24 4.8 22.92 4.8 21.6C4.8 20.268 5.868 19.2 7.2 19.2ZM18 10.8L21.336 4.8H6.168L9 10.8H18Z" fill="white"/>
          </svg>

          <span className="px-2 text-white text-xl font-bold text-center">Purchase Contact Lenses</span>
        </a>
        </div>
      )

}

export default CallButton
