import React from "react"
import { Link } from "gatsby"

import MakeAppointment from './makeAppointment'



const CallButton = (props) => {
  const number = props.phone
    if (number) {
      return (
        <div className="flex flex-col items-center">
        <a href={'tel:' + number}
          className="font-sans px-2 tracking-wide radial-gradient-green rounded shadow flex items-center block mx-auto text-center cursor-pointer phone justify-center mb-2">
          <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.0592 24.3441C21.0297 28.2167 24.2044 31.3914 28.077 33.362L31.0876 30.3515C31.4707 29.9683 32.0044 29.8588 32.4834 30.0093C34.016 30.5157 35.6581 30.7893 37.3686 30.7893C37.7316 30.7893 38.0796 30.9335 38.3362 31.1901C38.5929 31.4468 38.737 31.7948 38.737 32.1578V36.9472C38.737 37.3102 38.5929 37.6582 38.3362 37.9149C38.0796 38.1715 37.7316 38.3157 37.3686 38.3157C31.1989 38.3157 25.2818 35.8647 20.9191 31.502C16.5564 27.1394 14.1055 21.2223 14.1055 15.0525C14.1055 14.6896 14.2496 14.3415 14.5063 14.0849C14.7629 13.8283 15.111 13.6841 15.4739 13.6841H20.2634C20.6263 13.6841 20.9744 13.8283 21.231 14.0849C21.4876 14.3415 21.6318 14.6896 21.6318 15.0525C21.6318 16.763 21.9055 18.4051 22.4118 19.9378C22.5623 20.4167 22.4528 20.9504 22.0697 21.3336L19.0592 24.3441Z" fill="white" />
          </svg>
          <span className="pr-4 text-white text-xl font-bold text-center">Call for your consultation</span>
        </a>
          <MakeAppointment/>
        </div>
      )
    } else {
      return (
        <div className="flex flex-col items-center">
        <Link to="/locations"
          className="font-sans px-2 tracking-wide radial-gradient-green rounded shadow flex items-center block mx-auto text-center cursor-pointer phone justify-center mb-2">
          <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.0592 24.3441C21.0297 28.2167 24.2044 31.3914 28.077 33.362L31.0876 30.3515C31.4707 29.9683 32.0044 29.8588 32.4834 30.0093C34.016 30.5157 35.6581 30.7893 37.3686 30.7893C37.7316 30.7893 38.0796 30.9335 38.3362 31.1901C38.5929 31.4468 38.737 31.7948 38.737 32.1578V36.9472C38.737 37.3102 38.5929 37.6582 38.3362 37.9149C38.0796 38.1715 37.7316 38.3157 37.3686 38.3157C31.1989 38.3157 25.2818 35.8647 20.9191 31.502C16.5564 27.1394 14.1055 21.2223 14.1055 15.0525C14.1055 14.6896 14.2496 14.3415 14.5063 14.0849C14.7629 13.8283 15.111 13.6841 15.4739 13.6841H20.2634C20.6263 13.6841 20.9744 13.8283 21.231 14.0849C21.4876 14.3415 21.6318 14.6896 21.6318 15.0525C21.6318 16.763 21.9055 18.4051 22.4118 19.9378C22.5623 20.4167 22.4528 20.9504 22.0697 21.3336L19.0592 24.3441Z" fill="white" />
          </svg>
          <span className="pr-4 text-white text-xl font-bold text-center">Call for your consultation</span>
        </Link>
          <MakeAppointment />
        </div>
      )
    }
}

export default CallButton
